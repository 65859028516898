import { random } from "../../utils/helpers";

export const addConfidenceToAlerts = (alerts) => {
	return alerts.map((alert) => {
		return { ...alert, confidence: random(85, 95) };
	});
};

export const filterAlertsByPermission = (alerts, isAdmin) => {
	if (!isAdmin) {
		const filteredAlerts = alerts.filter((alert) => {
			if (alert.status === "PENDING") {
				return alert;
			}
			return null;
		});
		return addConfidenceToAlerts(filteredAlerts).reverse();
	} else {
		return addConfidenceToAlerts(alerts).reverse();
	}
};

export const getAlertCameraNamesArray = (alerts) => {
	const allNames = alerts.map((alert) => {
		return alert.camera.name;
	});

	return [...new Set(allNames)];
};

export const hasMoreThanOneYearOfAlerts = (alerts) => {
	if (!alerts || alerts.length === 0) return false;

	const timestamps = alerts.map((alert) => new Date(alert.timestamp).getTime());

	const earliestTimestamp = Math.min(...timestamps);
	const latestTimestamp = Math.max(...timestamps);

	const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;

	return latestTimestamp - earliestTimestamp > oneYearInMilliseconds;
};

export const getApprovalRateLast24h = (alerts) => {
	const now = new Date();

	const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);

	const recentAlerts = alerts.filter(
		(alert) => new Date(alert.timestamp) > twentyFourHoursAgo,
	);

	const pendingCount = recentAlerts.filter(
		(alert) => alert.status === "PENDING",
	).length;
	const approvedCount = recentAlerts.filter(
		(alert) => alert.status === "APPROVED",
	).length;
	const declinedCount = recentAlerts.filter(
		(alert) => alert.status === "DECLINED",
	).length;

	const approvalRate =
		((approvedCount + declinedCount) /
			(pendingCount + approvedCount + declinedCount)) *
		100;

	return approvalRate.toFixed(2);
};
