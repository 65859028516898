import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
	resetFilter,
	selectActiveCompany,
	selectActiveCompanyAlerts,
	selectActiveCompanyCameras,
	selectFilteredActiveCompanyAlerts,
} from "../../store/slices/userSlice";

import {
	selectDataState,
	selectDataStateSilent,
} from "../../store/slices/appStateSlice";

import {
	addConfidenceToAlerts,
	getApprovalRateLast24h,
	getAlertCameraNamesArray,
	hasMoreThanOneYearOfAlerts,
} from "./historyPage.helpers";

import HistoryPage from "./HistoryPage";
import { fetchAlerts } from "./historyPage.actions";
import { DATA_STATE } from "../../constants/app.constants";
import HistoryPageGraphContainer from "./Components/HistoryPageGraphContainer";
import { selectActiveHistoryPageComponent } from "../../store/slices/routingSlice";
import { HISTORY_PAGE_COMPONENT } from "../MainPage/MainPageSideMenu/Components/HistoryPageGraphOption";

const HistoryPageContainer = () => {
	const dispatch = useDispatch();

	const dataState = useSelector(selectDataState);
	const company = useSelector(selectActiveCompany);
	const alerts = useSelector(selectActiveCompanyAlerts);
	const dataStateSilent = useSelector(selectDataStateSilent);
	const filteredActiveCompanyAlerts = useSelector(
		selectFilteredActiveCompanyAlerts,
	);
	const activeHistoryPageComponent = useSelector(
		selectActiveHistoryPageComponent,
	);
	const activeCompanyCameras = useSelector(selectActiveCompanyCameras);

	useEffect(() => {
		if (company) dispatch(fetchAlerts(company.id));

		return () => {
			dispatch(resetFilter());
		};
	}, [company, dispatch]);

	if (dataState === DATA_STATE.DATA_STATE_OK && alerts) {
		if (activeHistoryPageComponent === HISTORY_PAGE_COMPONENT.TABLE)
			return (
				<HistoryPage
					company={company}
					dataStateSilent={dataStateSilent}
					alerts={
						filteredActiveCompanyAlerts
							? addConfidenceToAlerts(filteredActiveCompanyAlerts).reverse()
							: addConfidenceToAlerts(alerts).reverse()
					}
					alertCameraNames={getAlertCameraNamesArray(alerts)}
					allAlerts={alerts}
				/>
			);
		if (activeHistoryPageComponent === HISTORY_PAGE_COMPONENT.GRAPH)
			return (
				<HistoryPageGraphContainer
					alerts={alerts}
					hasMoreThanOneYearOfAlerts={hasMoreThanOneYearOfAlerts(alerts)}
					approvalRate={getApprovalRateLast24h(alerts)}
					activeCompanyCameras={activeCompanyCameras}
				/>
			);
	} else if (dataState === DATA_STATE.DATA_STATE_OK && !alerts) {
		return (
			<div
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CircularProgress color="primary" size={60} thickness={5} />
				<p className="loading-modal-txt">Loading alerts...</p>
			</div>
		);
	}
};

export default HistoryPageContainer;
