import { useState, useEffect, useCallback } from "react";
import {
	Line,
	XAxis,
	YAxis,
	Legend,
	Tooltip,
	LineChart,
	CartesianGrid,
	ResponsiveContainer,
} from "recharts";

const MONTH_NAMES = [
	"JAN",
	"FEB",
	"MAR",
	"APR",
	"MAY",
	"JUN",
	"JUL",
	"AUG",
	"SEP",
	"OCT",
	"NOV",
	"DEC",
];

const HistoryPageLineChart = ({ alerts }) => {
	const [transformedAlerts, setTransformedAlerts] = useState([]);

	const getFormattedDate = (timestamp) => {
		const date = new Date(timestamp);
		return `${MONTH_NAMES[date.getMonth()]}-${date.getFullYear()}`;
	};

	const aggregateDataByDate = useCallback((alerts) => {
		const aggregatedData = {};

		alerts.forEach((alert) => {
			const dateKey = getFormattedDate(alert.timestamp);
			if (!aggregatedData[dateKey]) {
				aggregatedData[dateKey] = {
					timestamp: dateKey,
					approved: 0,
					declined: 0,
				};
			}
			if (alert.status === "APPROVED") {
				aggregatedData[dateKey].approved += 1;
			} else if (alert.status === "DECLINED") {
				aggregatedData[dateKey].declined += 1;
			}
		});

		return Object.values(aggregatedData);
	}, []);

	useEffect(() => {
		if (alerts && alerts.length > 0) {
			setTransformedAlerts(aggregateDataByDate(alerts));
		}
	}, [alerts, aggregateDataByDate]);

	return (
		<ResponsiveContainer>
			<LineChart
				data={transformedAlerts}
				margin={{ top: 5, left: 10, right: 25, bottom: 5 }}
			>
				<XAxis dataKey="timestamp" padding={{ right: 25 }} />
				<YAxis />
				<Tooltip />
				<CartesianGrid stroke="#f5f5f5" />
				<Legend
					verticalAlign="top"
					height={36}
					wrapperStyle={{ fontFamily: "Mollen", fontSize: "16px" }}
				/>
				<Line
					type="monotone"
					dataKey="approved"
					stroke="#23C8AA"
					dot={{ stroke: "#23C8AA", strokeWidth: 5 }}
				/>
				<Line
					type="monotone"
					dataKey="declined"
					stroke="#FFA07A"
					dot={{ stroke: "#FFA07A", strokeWidth: 5 }}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default HistoryPageLineChart;
