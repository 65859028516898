import { SERVER_ADDRESS, WEATHER_API_KEY } from "./app.constants";

export const LOGIN_USER = `${SERVER_ADDRESS}/api/login/`;
export const GET_USER_DATA = `${SERVER_ADDRESS}/api/user/`;
export const ADD_NEW_CAMERA = `${SERVER_ADDRESS}/api/camera/`;
export const REGISTER_USER = `${SERVER_ADDRESS}/api/register/`;
export const REFRESH_TOKEN = `${SERVER_ADDRESS}/api/token/refresh/`;
export const TEST_ALERT_PHONE = `${SERVER_ADDRESS}/api/test_number/`;
export const CREATE_COMPANY_ALERT_PHONE = `${SERVER_ADDRESS}/api/contact/`;

export const GET_COMPANY_CAMERAS = (companyId) => {
	return `${SERVER_ADDRESS}/api/company/${companyId}/cameras/`;
};

export const CAMERA_STREAM = (cameraId) => {
	return `${SERVER_ADDRESS}/api/camera/${cameraId}/stream/`;
};

export const CAMERA_SNAPSHOT = (snapshotId) => {
	return `https://spectro-media-bucket.s3.amazonaws.com/snapshots/${snapshotId}.png`;
};

export const GET_COMPANY_ALERT_PHONES = (companyId) => {
	return `${SERVER_ADDRESS}/api/company/${companyId}/contacts/`;
};

export const DELETE_COMPANY_ALERT_PHONE = (phoneId) => {
	return `${SERVER_ADDRESS}/api/contact/${phoneId}/`;
};

export const GET_WEATHER_DATA = (location) => {
	return `https://api.openweathermap.org/data/2.5/weather?lat=${location.lat}&lon=${location.long}&appid=${WEATHER_API_KEY}&units=metric`;
};

export const GET_ALERTS = (companyId) => {
	if (companyId) {
		return `${SERVER_ADDRESS}/api/anomalies/${companyId}/`;
	} else {
		return null;
	}
};

export const GET_ALERTS_LATEST = (companyId) => {
	if (companyId) {
		return `${SERVER_ADDRESS}/api/anomalies/${companyId}/latest/`;
	} else {
		return null;
	}
};

export const DELETE_ALERT = (alertId) => {
	return `https://api.spectro-solutions.com/api/snapshot/${alertId}/`;
};

export const APPROVE_ALERT = (alertId) =>
	`https://api.spectro-solutions.com/api/snapshot/${alertId}/approve/`;

export const REJECT_ALERT = (alertId) =>
	`https://api.spectro-solutions.com/api/snapshot/${alertId}/decline/`;

export const DELETE_CAMERA = (cameraId) =>
	`https://api.spectro-solutions.com/api/camera/${cameraId}/`;

export const EDIT_CAMERA = (cameraId) =>
	`https://api.spectro-solutions.com/api/camera/${cameraId}/`;

export const GET_ALERTS_FOR_CAMERA_ID = (cameraId) =>
	`https://api.spectro-solutions.com/api/camera/${cameraId}/anomalies/`;

export const GET_ALERTS_WITH_FILTERS = (
	limit,
	preset,
	status,
	cameraId,
	companyId,
) => {
	const base = `${SERVER_ADDRESS}/api/anomalies/${companyId}/`;

	const params = new URLSearchParams();
	if (limit !== undefined) params.append("limit", limit);
	if (cameraId !== undefined) params.append("camera_id", cameraId);
	if (preset !== undefined && preset !== null) params.append("preset", preset);
	if (status !== undefined) params.append("status", status);

	return `${base}?${params.toString()}`;
};
