import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import {
	resetFilter,
	selectActiveCompanyAlerts,
} from "../../store/slices/userSlice";

import logo from "../../assets/logo_white.png";
import StreamAlert from "./components/StreamAlert";
import { LOCAL_GREY } from "../../constants/colors";
import { fetchAlertsLatest } from "../HistoryPage/historyPage.actions";

import "./streamAndAlerts.styles.css";

const StreamAndAlertsPageContainer = () => {
	const dispatch = useDispatch();
	const alerts = useSelector(selectActiveCompanyAlerts);

	useEffect(() => {
		dispatch(fetchAlertsLatest(4, true, false));

		const intervalId = setInterval(() => {
			dispatch(fetchAlertsLatest(4, true, false));
		}, 1000);

		return () => {
			dispatch(resetFilter());
			clearInterval(intervalId);
		};
	}, [dispatch]);

	if (!alerts) return null;

	return (
		<div
			style={{
				width: "512px",
				height: "512px",
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "12%",
					backgroundColor: LOCAL_GREY,
					position: "relative",
					width: "100%",
					margin: "0 auto",
					overflow: "hidden",
					padding: "10px",
				}}
			>
				<img
					src={logo}
					alt="Company Logo"
					style={{
						width: "100%",
						height: "100%",
						objectFit: "contain",
						objectPosition: "center",
					}}
				/>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					overflow: "auto",
					backgroundColor: "#e7ebf0",
					padding: "10px",
					gap: "10px",
				}}
			>
				<TransitionGroup component={null}>
					{alerts
						.filter((alert) => alert.status === "APPROVED")
						.map((alert) => (
							<CSSTransition key={alert.id} timeout={500} classNames="fade">
								<StreamAlert alert={alert} />
							</CSSTransition>
						))}
				</TransitionGroup>
			</div>
		</div>
	);
};

export default StreamAndAlertsPageContainer;
