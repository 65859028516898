import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		cameraAlert: {
			main: "#FFA500",
		},
		mainGreen: {
			main: "#23C8AA",
		},
		colorWhite: {
			main: "#FFFFFF",
		},
		graphOrange: {
			main: "#FFA07A",
		},
	},
});
