import React, { useState, useEffect } from "react";

import { formatDate } from "../../../utils/helpers";
import { LOCAL_ORANGE } from "../../../constants/colors";

const StreamAlert = ({ alert, newAlert }) => {
	const [highlight, setHighlight] = useState(true);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setHighlight(false);
		}, 3000);

		return () => clearTimeout(timeoutId);
	}, []);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				padding: "10px",
				borderRadius: "8px",
				backgroundColor: highlight ? LOCAL_ORANGE : "white",
				transition: "0.4s",
			}}
		>
			<p className="stream-alert-txt">{formatDate(alert.timestamp)}</p>
			{/* eslint-disable-next-line */}
			<img src={alert.image} alt="Test Image" style={{ width: "100%" }} />
		</div>
	);
};

export default StreamAlert;
