import React, { useMemo } from "react";
import { FaHistory } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { GiCctvCamera } from "react-icons/gi";
import { useLocation } from "react-router-dom";

import CamerasListSwitch from "./Components/CamerasListSwitch";
import { APP_VERSION } from "../../../constants/app.constants";
import CustomLink from "../../../Components/CustomLink/CustomLink";
import MainPageSideMenuOption from "./MainPageOption/MainPageSideMenuOption";

import "./styles.css";
import HistoryPageGraphOption from "./Components/HistoryPageGraphOption";

const MainPageSideMenu = () => {
	const { pathname } = useLocation();

	const sideMenuOptions = useMemo(
		() => [
			{
				id: 1,
				text: "My Cameras",
				options: [<CamerasListSwitch />],
				to: "/cameras",
				includesPath: ["/cameras"],
				active: false,
				get icon() {
					return (
						<GiCctvCamera size={30} color={this.active ? "#23c8aa" : "white"} />
					);
				},
			},
			{
				id: 2,
				text: "Settings",
				to: "/settings",
				includesPath: ["/settings"],
				active: false,
				get icon() {
					return (
						<FiSettings size={30} color={this.active ? "#23c8aa" : "white"} />
					);
				},
			},
			{
				id: 3,
				text: "History log",
				options: [<HistoryPageGraphOption />],
				to: "/history",
				includesPath: ["/history", "/history/graph"],
				active: false,
				get icon() {
					return (
						<FaHistory size={30} color={this.active ? "#23c8aa" : "white"} />
					);
				},
			},
		],
		[],
	);

	const renderSubOptions = (option) => {
		if (!option.options) return null;
		return option.options.map((subOption) => (
			<React.Fragment key={option.id}>{subOption}</React.Fragment>
		));
	};

	return (
		<div className="side-menu-component-body">
			{sideMenuOptions.map((option) => {
				return (
					<React.Fragment key={option.id}>
						<CustomLink to={option.to}>
							<MainPageSideMenuOption option={option} icon={option.icon} />
						</CustomLink>
						{option.includesPath.includes(pathname)
							? renderSubOptions(option)
							: null}
					</React.Fragment>
				);
			})}
			<p className="version-txt">{APP_VERSION}</p>
		</div>
	);
};

export default MainPageSideMenu;
