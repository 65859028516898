import {
	setDataState,
	setDataStateSilent,
	displayNotification,
} from "../../store/slices/appStateSlice";

import {
	DATA_STATE,
	MODAL_TYPE,
	SILENT_ACTIONS,
} from "../../constants/app.constants";

import {
	GET_ALERTS,
	DELETE_ALERT,
	REJECT_ALERT,
	APPROVE_ALERT,
	GET_ALERTS_LATEST,
	GET_ALERTS_WITH_FILTERS,
} from "../../constants/url";

import {
	filterCompanyAlerts,
	setActiveCompanyAlerts,
	setFilteredActiveCompanyAlerts,
} from "../../store/slices/userSlice";

import axiosInstance from "../../utils/axios";

export const fetchAlerts =
	(companyId, disableLoadingModal = false, deleteAlerts = true) =>
	async (dispatch) => {
		!disableLoadingModal &&
			dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		// deleteAlerts && dispatch(deleteCompanyAlerts());
		await axiosInstance
			.get(GET_ALERTS(companyId))
			.then((response) => {
				dispatch(setActiveCompanyAlerts(response.data));
			})
			.catch(() => {
				const payload = {
					text: "Error getting alerts",
					type: MODAL_TYPE.FAILURE,
				};
				dispatch(displayNotification(payload));
			})
			.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
	};

export const fetchAlertsLatest =
	(companyId, disableLoadingModal = false, deleteAlerts = true) =>
	async (dispatch) => {
		!disableLoadingModal &&
			dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		// deleteAlerts && dispatch(deleteCompanyAlerts());
		await axiosInstance
			.get(GET_ALERTS_LATEST(companyId))
			.then((response) => {
				dispatch(setActiveCompanyAlerts(response.data));
			})
			.catch(() => {
				const payload = {
					text: "Error getting alerts",
					type: MODAL_TYPE.FAILURE,
				};
				dispatch(displayNotification(payload));
			})
			.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
	};

export const fetchAlertsSilent = (companyId) => async (dispatch) => {
	const payload = {
		action: SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS,
		dataState: DATA_STATE.DATA_STATE_LOADING,
	};
	dispatch(setDataStateSilent(payload));
	await axiosInstance
		.get(GET_ALERTS(companyId))
		.then((response) => {
			dispatch(setActiveCompanyAlerts(response.data));
		})
		.catch(() => {
			const payload = {
				text: "Error getting alerts",
				type: MODAL_TYPE.FAILURE,
			};
			dispatch(displayNotification(payload));
		})
		.finally(() => {
			const payload = {
				action: null,
				dataState: DATA_STATE.DATA_STATE_OK,
			};
			dispatch(setDataStateSilent(payload));
		});
};

export const deleteAlertSilent = (alertId, companyId) => async (dispatch) => {
	const payload = {
		action: SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS,
		dataState: DATA_STATE.DATA_STATE_LOADING,
	};
	dispatch(setDataStateSilent(payload));
	await axiosInstance
		.delete(DELETE_ALERT(alertId))
		.then(() => {
			const payload = {
				text: "Alert deleted successfully",
				type: MODAL_TYPE.SUCCESS,
			};
			dispatch(displayNotification(payload));
		})
		.catch(() => {
			const payload = {
				text: "Error deleting alert",
				type: MODAL_TYPE.FAILURE,
			};
			dispatch(displayNotification(payload));
		})
		.finally(() => {
			dispatch(filterCompanyAlerts(alertId));
			const payload = {
				action: null,
				dataState: DATA_STATE.DATA_STATE_OK,
			};
			dispatch(setDataStateSilent(payload));
		});
};

export const ApproveAlertSilent = (alertId, companyId) => async (dispatch) => {
	const payload = {
		action: SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS,
		dataState: DATA_STATE.DATA_STATE_LOADING,
	};
	dispatch(setDataStateSilent(payload));
	await axiosInstance
		.post(APPROVE_ALERT(alertId))
		.then(() => {
			const payload = {
				text: "Alert approved successfully",
				type: MODAL_TYPE.SUCCESS,
			};
			dispatch(displayNotification(payload));
		})
		.catch(() => {
			const payload = {
				text: "Error approving alert",
				type: MODAL_TYPE.FAILURE,
			};
			dispatch(displayNotification(payload));
		})
		.finally(() => {
			dispatch(filterCompanyAlerts(alertId));
			const payload = {
				action: null,
				dataState: DATA_STATE.DATA_STATE_OK,
			};
			dispatch(setDataStateSilent(payload));
		});
};

export const rejectAlertSilent = (alertId, companyId) => async (dispatch) => {
	const payload = {
		action: SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS,
		dataState: DATA_STATE.DATA_STATE_LOADING,
	};
	dispatch(setDataStateSilent(payload));
	await axiosInstance
		.post(REJECT_ALERT(alertId))
		.then(() => {
			const payload = {
				text: "Alert rejected successfully",
				type: MODAL_TYPE.SUCCESS,
			};
			dispatch(displayNotification(payload));
		})
		.catch(() => {
			const payload = {
				text: "Error rejecting alert",
				type: MODAL_TYPE.FAILURE,
			};
			dispatch(displayNotification(payload));
		})
		.finally(() => {
			dispatch(filterCompanyAlerts(alertId));
			const payload = {
				action: null,
				dataState: DATA_STATE.DATA_STATE_OK,
			};
			dispatch(setDataStateSilent(payload));
		});
};

export const filterAlertsByCameraName = (name, alerts) => (dispatch) => {
	const newAlerts = alerts.filter((alert) => {
		if (alert.camera.name === name) {
			return alert;
		} else {
			return null;
		}
	});

	dispatch(setFilteredActiveCompanyAlerts(newAlerts));
};

export const filterAlertsByStatus = (status, alerts) => (dispatch) => {
	const newAlerts = alerts.filter((alert) => {
		if (alert.status === status) {
			return alert;
		} else {
			return null;
		}
	});
	const payload = {
		status,
		newAlerts,
	};
	dispatch(setFilteredActiveCompanyAlerts(payload));
};

export const filterAlertsByStatusNoAction = (status, alerts) => {
	const newAlerts = alerts.filter((alert) => {
		if (alert.status === status) {
			return alert;
		} else {
			return null;
		}
	});

	return newAlerts;
};

export const filterAlerts =
	(limit, preset, status, cameraId, companyId) => async (dispatch) => {
		const payload = {
			action: SILENT_ACTIONS.HISTORY_PAGE_LOAD_ALERTS,
			dataState: DATA_STATE.DATA_STATE_LOADING,
		};
		dispatch(setDataStateSilent(payload));
		await axiosInstance
			.get(GET_ALERTS_WITH_FILTERS(limit, preset, status, cameraId, companyId))
			.then((response) => {
				if (response.data.length === 0) {
					const payload = {
						text: "No alerts found with this filter setup",
						type: MODAL_TYPE.FAILURE,
					};
					dispatch(displayNotification(payload));
				} else {
					dispatch(setActiveCompanyAlerts(response.data.reverse()));
				}
			})
			.catch(() => {
				const payload = {
					text: "Error getting alerts",
					type: MODAL_TYPE.FAILURE,
				};
				dispatch(displayNotification(payload));
			})
			.finally(() => {
				const payload = {
					action: null,
					dataState: DATA_STATE.DATA_STATE_OK,
				};
				dispatch(setDataStateSilent(payload));
			});
	};
