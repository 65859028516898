import React from "react";

const ProgressArc = ({ percentage }) => {
	const strokeWidth = 10;
	const viewBox = 100;
	const radius = 50 - strokeWidth / 2;
	const startAngle = -90;
	const endAngle = startAngle + (percentage / 100) * 180;

	const describeArc = (x, y, radius, startAngle, endAngle) => {
		const start = polarToCartesian(x, y, radius, endAngle);
		const end = polarToCartesian(x, y, radius, startAngle);
		const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

		return [
			"M",
			start.x,
			start.y,
			"A",
			radius,
			radius,
			0,
			largeArcFlag,
			0,
			end.x,
			end.y,
		].join(" ");
	};

	const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
		const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
		return {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians),
		};
	};

	return (
		<svg width="100%" height="50%" viewBox={`0 0 ${viewBox} ${viewBox / 2}`}>
			<path
				d={describeArc(50, 50, radius, startAngle, startAngle + 180)}
				fill="none"
				stroke="#eee"
				strokeWidth={strokeWidth}
			/>
			<path
				d={describeArc(50, 50, radius, startAngle, endAngle)}
				fill="none"
				stroke="#FFA07A"
				strokeWidth={strokeWidth}
			/>
			<text
				x="50"
				y="40"
				fontSize="16"
				textAnchor="middle"
				dominantBaseline="central"
				style={{ fontFamily: "Mollen", fontSize: "large", color: "#213a44" }}
			>
				{percentage}%
			</text>
		</svg>
	);
};

export default ProgressArc;
