/* eslint-disable react/jsx-pascal-case */
import React from "react";

import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { store } from "./store/store";
import MainPage from "./Pages/MainPage/MainPage";
import AppBase from "./Components/AppBase/AppBase";
import SignupPage from "./Pages/SignupPage/SignupPage";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import LoginPage_a from "./Pages/LoginPageActual/LoginPage_a";
import CamerasPage from "./Pages/MainPage/CamerasPage/CamerasPage";
import HistoryPageContainer from "./Pages/HistoryPage/HistoryPageContainer";
import StreamAndAlertsPageContainer from "./Pages/StreamAndAlertsPage/StreamAndAlertsPageContainer";

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <AppBase>
                    <Routes>
                        <Route path="/" element={<MainPage />}>
                            <Route index element={<CamerasPage />} />
                            <Route
                                path="/cameras"
                                index
                                element={<CamerasPage />}
                            />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/settings" element={<ProfilePage />} />
                            <Route
                                path="/history"
                                element={<HistoryPageContainer />}
                            />
                        </Route>
                        <Route path="/login" element={<LoginPage_a />} />
                        <Route path="/register" element={<SignupPage />} />
                        <Route
                            path="/streamandalerts"
                            element={<StreamAndAlertsPageContainer />}
                        />
                    </Routes>
                </AppBase>
            </Router>
        </Provider>
    );
};

export default App;
