// export const SERVER_ADDRESS = "http://127.0.0.1:8000";
export const APP_VERSION = "v1.3.7";

export const SERVER_ADDRESS = "https://api.spectro-solutions.com";

export const WEATHER_API_KEY = "eacfaf5b3696a8a08aabfd3b27cf1354";

export const DATA_STATE = {
	DATA_STATE_LOADING: "Loading",
	DATA_STATE_OK: "OK",
};

export const ACTIVE_MODAL = {
	EDIT_CAMERA: "EDIT_CAMERA",
	WEATHER_MODAL: "WEATHER_MODAL",
	CAMERA_STREAM: "Camera stream",
	ADD_NEW_CAMERA: "Add new camera",
	NEW_COMPANY_ADD: "Add new company",
	DEMO_CAMERA_STREAM: "DEMO_CAMERA_STREAM",
	UPDATE_PROFILE_INFO: "Update profile info",
	ADD_NEW_ALERT_PHONE: "Add new alert phone",
};

export const MODAL_TYPE = {
	SUCCESS: "SUCCESS",
	FAILURE: "FAILURE",
};

export const ACTIVE_ROUTES = {
	MAIN: "/",
	LOGIN: "/login",
};

export const PERMISSION_LEVEL = {
	ADMIN: "ADMIN",
	AGENT: "AGENT",
};

export const SILENT_ACTIONS = {
	HISTORY_PAGE_LOAD_ALERTS: "HISTORY_PAGE_LOAD_ALERTS",
};

export const ALERT_STATUS = {
	PENDING: "PENDING",
	APPROVED: "APPROVED",
	DECLINED: "DECLINED",
};
