import axiosInstance from "../../../../../utils/axios";

import {
	REJECT_ALERT,
	DELETE_ALERT,
	APPROVE_ALERT,
	GET_COMPANY_CAMERAS,
	GET_ALERTS_WITH_FILTERS,
} from "../../../../../constants/url";

import {
	setDataState,
	displayNotification,
} from "../../../../../store/slices/appStateSlice";

import {
	setAlertsForCamera,
	setActiveCompanyCameras,
} from "../../../../../store/slices/userSlice";

import { DATA_STATE, MODAL_TYPE } from "../../../../../constants/app.constants";

export const CAMERAS_LIST_ACTIONS = {
	FETCH_COMPANY_CAMERAS: "FETCH_COMPANY_CAMERAS",
};

export const fetchAlertsForCamera =
	(cameraId, companyId) => async (dispatch) => {
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		await axiosInstance
			.get(
				GET_ALERTS_WITH_FILTERS(undefined, undefined, "p", cameraId, companyId),
			)
			.then((response) => {
				dispatch(setAlertsForCamera(response.data));
			})
			.catch(() => {
				const payload = {
					text: "Error getting alerts",
					type: MODAL_TYPE.FAILURE,
				};
				dispatch(displayNotification(payload));
			})
			.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
	};

export const fetchCompanyCameras = (companyId) => async (dispatch) => {
	dispatch(setDataState(CAMERAS_LIST_ACTIONS.FETCH_COMPANY_CAMERAS));
	await axiosInstance
		.get(GET_COMPANY_CAMERAS(companyId))
		.then((response) => {
			dispatch(setActiveCompanyCameras(response.data.results));
		})
		.catch(() => {
			const payload = {
				text: "Error refreshing cameras",
				type: MODAL_TYPE.FAILURE,
			};
			dispatch(displayNotification(payload));
		})
		.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
};

export const deleteAlert =
	(alertId, cameraId, companyId) => async (dispatch) => {
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		await axiosInstance
			.delete(DELETE_ALERT(alertId))
			.then(() => {
				const payload = {
					text: "Alert deleted successfully",
					type: MODAL_TYPE.SUCCESS,
				};
				dispatch(displayNotification(payload));
			})
			.catch(() => {
				const payload = {
					text: "Error deleting alert",
					type: MODAL_TYPE.FAILURE,
				};
				dispatch(displayNotification(payload));
			})
			.finally(() => {
				dispatch(fetchAlertsForCamera(cameraId));
				dispatch(fetchCompanyCameras(companyId));
				dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
			});
	};

export const approveAlert =
	(alertId, cameraId, companyId) => async (dispatch) => {
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		await axiosInstance
			.post(APPROVE_ALERT(alertId))
			.then(() => {
				const payload = {
					text: "Alert approved successfully",
					type: MODAL_TYPE.SUCCESS,
				};
				dispatch(displayNotification(payload));
			})
			.catch(() => {
				const payload = {
					text: "Error approving alert",
					type: MODAL_TYPE.FAILURE,
				};
				dispatch(displayNotification(payload));
			})
			.finally(() => {
				dispatch(fetchAlertsForCamera(cameraId));
				dispatch(fetchCompanyCameras(companyId));
				dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
			});
	};

export const rejectAlert =
	(alertId, cameraId, companyId) => async (dispatch) => {
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		await axiosInstance
			.post(REJECT_ALERT(alertId))
			.then(() => {
				const payload = {
					text: "Alert rejected successfully",
					type: MODAL_TYPE.SUCCESS,
				};
				dispatch(displayNotification(payload));
			})
			.catch(() => {
				const payload = {
					text: "Error rejecting alert",
					type: MODAL_TYPE.FAILURE,
				};
				dispatch(displayNotification(payload));
			})
			.finally(() => {
				dispatch(fetchAlertsForCamera(cameraId));
				dispatch(fetchCompanyCameras(companyId));
				dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
			});
	};
