import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Select,
	Button,
	MenuItem,
	InputLabel,
	FormControl,
	CircularProgress,
} from "@mui/material";

import {
	selectUser,
	selectActiveCompany,
	selectActiveCompanyCameras,
} from "../../../store/slices/userSlice";

import { filterAlerts } from "../historyPage.actions";

import { selectDataStateSilent } from "../../../store/slices/appStateSlice";
import { ALERT_STATUS, DATA_STATE } from "../../../constants/app.constants";

import "./historyPageComponents.styles.css";

const buttonSx = {
	backgroundColor: "#213a44",
};

const statusStringToParam = (statusString) => {
	switch (statusString) {
		case "PENDING":
			return "p";
		case "APPROVED":
			return "a";
		case "DECLINED":
			return "d";
		default:
			return undefined;
	}
};

const generatePresetArray = (presetMax) => {
	if (typeof presetMax !== "number" || presetMax <= 0) {
		return [];
	}

	const result = [];
	for (let i = 1; i <= presetMax; i++) {
		result.push(i);
	}
	return result;
};

const generatePresetFilter = (cameraId, cameras) => {
	if (typeof cameraId === "undefined" || !Array.isArray(cameras)) {
		return [];
	}

	const camera = cameras.find((camera) => camera.id === cameraId);

	if (!camera || typeof camera.presets !== "number") {
		return [];
	}

	return generatePresetArray(camera.presets);
};

const HistoryPageTableFilters = ({ allAlerts }) => {
	const dispatch = useDispatch();

	const [filterStatus, setFilterStatus] = useState(undefined);
	const [cameraFilter, setCameraFilter] = useState(undefined);
	const [presetsFilter, setPresetsFilter] = useState(undefined);

	const user = useSelector(selectUser);
	const company = useSelector(selectActiveCompany);
	const cameras = useSelector(selectActiveCompanyCameras);
	const { dataState } = useSelector(selectDataStateSilent);

	const handleStatusFilterChange = (event) => {
		setFilterStatus(event.target.value);
	};

	const handleCameraFilterChange = (event) => {
		setCameraFilter(event.target.value);
	};

	const handlePresetFilterChange = (event) => {
		setPresetsFilter(event.target.value);
	};

	useEffect(() => {
		const handleStatusChange = () => {
			dispatch(
				filterAlerts(
					100,
					presetsFilter,
					statusStringToParam(filterStatus),
					cameraFilter,
					company.id,
				),
			);
		};

		handleStatusChange();
	}, [filterStatus, cameraFilter, company, dispatch, presetsFilter]);

	const handleFilterReset = () => {
		setFilterStatus(undefined);
		setCameraFilter(undefined);
		setPresetsFilter(undefined);
	};

	if (!user) return <CircularProgress />;

	return (
		<div className="history-page-filters-container">
			<div className="history-page-filters-select-container">
				<FormControl fullWidth>
					<InputLabel id="statusFilterLabel">Status</InputLabel>
					<Select
						labelId="statusFilterLabel"
						id="statusFilter"
						label="status"
						value={filterStatus || ""}
						onChange={handleStatusFilterChange}
						disabled={
							allAlerts.length === 0 ||
							dataState === DATA_STATE.DATA_STATE_LOADING
						}
					>
						{user.is_admin ? (
							<MenuItem value={ALERT_STATUS.PENDING}>PENDING</MenuItem>
						) : null}
						<MenuItem value={ALERT_STATUS.APPROVED}>APPROVED</MenuItem>
						<MenuItem value={ALERT_STATUS.DECLINED}>DECLINED</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id="cameraFilterLabel">Camera</InputLabel>
					<Select
						labelId="cameraFilterLabel"
						id="cameraFilter"
						label="camera"
						value={cameraFilter || ""}
						onChange={handleCameraFilterChange}
						disabled={
							allAlerts.length === 0 ||
							dataState === DATA_STATE.DATA_STATE_LOADING
						}
					>
						{cameras.map((camera) => (
							<MenuItem value={camera.id}>{camera.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id="presetFilterLabel">Preset</InputLabel>
					<Select
						labelId="presetFilterLabel"
						id="presetFilter"
						label="Preset"
						value={presetsFilter || ""}
						onChange={handlePresetFilterChange}
						disabled={
							!cameraFilter || dataState === DATA_STATE.DATA_STATE_LOADING
						}
					>
						<MenuItem value={null}>ALL</MenuItem>
						{generatePresetFilter(cameraFilter, cameras).map((preset) => (
							<MenuItem value={preset}>{preset}</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<Button
				variant="contained"
				sx={buttonSx}
				onClick={handleFilterReset}
				disabled={allAlerts.length === 0 || (!filterStatus && !cameraFilter)}
			>
				<p className="history-page-filters-button-txt">Reset filters</p>
			</Button>
		</div>
	);
};

export default HistoryPageTableFilters;
