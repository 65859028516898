import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
	selectUser,
	selectActiveCompany,
	selectAlertsForCamera,
} from "../../../../../store/slices/userSlice";

import { fetchAlertsForCamera } from "./camerasList.actions";
import CamerasListDetailedTableActiveLogs from "./Components/CamerasListDetailedTableActiveLogs";
import CamerasListDetailedTableHistoryLogs from "./Components/CamerasListDetailedTableHistoryLogs";

import "./styles.css";

const CamerasListDetailedLogsContainer = ({ selectedCamera }) => {
	const dispatch = useDispatch();

	const user = useSelector(selectUser);
	const company = useSelector(selectActiveCompany);
	const alertsForCamera = useSelector(selectAlertsForCamera);

	useEffect(() => {
		if (selectedCamera)
			dispatch(fetchAlertsForCamera(selectedCamera, company.id));
	}, [selectedCamera, dispatch, company]);

	if (!alertsForCamera) {
		return (
			<div className="detailed-cameras-list-no-selection-container">
				<CircularProgress />
			</div>
		);
	}

	if (alertsForCamera && company && user)
		return (
			<div className="detailed-cameras-list-tables-container">
				{user.is_admin ? (
					<div className="detailed-cameras-list-table-wrapper">
						{alertsForCamera.filter((alert) => alert.status === "PENDING")
							.length === 0 ? (
							<p className="camera-card-txt">
								No pending alerts for this camera
							</p>
						) : (
							<>
								<p className="camera-card-txt">Active logs</p>
								<CamerasListDetailedTableActiveLogs
									alerts={alertsForCamera
										.filter((alert) => alert.status === "PENDING")
										.reverse()}
									company={company}
									cameraId={selectedCamera}
								/>
							</>
						)}
					</div>
				) : (
					<div
						className={
							user.is_admin === true
								? "detailed-cameras-list-table-wrapper"
								: "detailed-cameras-list-table-wrapper-no-admin"
						}
					>
						<p className="camera-card-txt">Camera history</p>
						<CamerasListDetailedTableHistoryLogs
							alerts={alertsForCamera
								.filter((alert) => alert.status === "APPROVED")
								.reverse()}
							company={company}
							cameraId={selectedCamera}
						/>
					</div>
				)}
			</div>
		);
};

export default CamerasListDetailedLogsContainer;
