import axiosInstance from "../../utils/axios";

import {
	GET_USER_DATA,
	GET_COMPANY_CAMERAS,
	GET_COMPANY_ALERT_PHONES,
} from "../../constants/url";

import {
	setUser,
	setActiveCompany,
	setActiveCompanyCameras,
	setActiveCompanyAlertPhones,
} from "../../store/slices/userSlice";

import { DATA_STATE } from "../../constants/app.constants";
import { setDataState } from "../../store/slices/appStateSlice";

export const dispatchFetchUser = () => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	return new Promise((resolve, reject) => {
		axiosInstance
			.get(GET_USER_DATA)
			.then((response) => {
				const userData = response.data;
				dispatch(setUser(userData));
				dispatch(setActiveCompany(userData.companies[0]));
				resolve(userData.companies[0].id);
			})
			.catch((err) => {
				reject(err);
			})
			.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
	});
};

export const dispatchFetchCompanyCameras = (companyId) => async (dispatch) => {
	dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
	return new Promise((resolve, reject) => {
		axiosInstance
			.get(GET_COMPANY_CAMERAS(companyId))
			.then((response) => {
				const data = response.data;
				dispatch(setActiveCompanyCameras(data));
				resolve(companyId);
			})
			.catch((err) => {
				const error = err.message;
				reject(error);
			})
			.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
	});
};

export const dispatchFetchCompanyAlertPhones =
	(companyId) => async (dispatch) => {
		dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
		return new Promise((resolve, reject) => {
			axiosInstance
				.get(GET_COMPANY_ALERT_PHONES(companyId))
				.then((response) => {
					const data = response.data;
					dispatch(setActiveCompanyAlertPhones(data));
					resolve(data);
				})
				.catch((err) => {
					console.log(err);
					const error = err.message;
					reject(error);
				})
				.finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
		});
	};
