import { useState, useEffect, useCallback } from "react";

import {
	Area,
	XAxis,
	YAxis,
	Tooltip,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
} from "recharts";

import { FILTER_VALUES } from "./HistoryPageGraphContainer";

const MONTH_NAMES = [
	"JAN",
	"FEB",
	"MAR",
	"APR",
	"MAY",
	"JUN",
	"JUL",
	"AUG",
	"SEP",
	"OCT",
	"NOV",
	"DEC",
];

const HistoryPageAreaGraph = ({ alerts, period }) => {
	const [transformedAlerts, setTransformedAlerts] = useState([]);

	const getFormattedDate = useCallback(
		(timestamp) => {
			const date = new Date(timestamp);
			switch (period) {
				case FILTER_VALUES.YEARLY:
					return `${date.getFullYear()}`;
				case FILTER_VALUES.WEEKLY:
					const weekNumber = getWeekNumber(date);
					return `${date.getFullYear()}-W${weekNumber}`;
				case FILTER_VALUES.MONTHLY:
				default:
					return `${MONTH_NAMES[date.getMonth()]}-${date.getFullYear()}`;
			}
		},
		[period],
	);

	function getWeekNumber(date) {
		const start = new Date(date.getFullYear(), 0, 1);
		const days = Math.floor((date - start) / (1000 * 60 * 60 * 24));
		return Math.ceil((days + start.getDay() + 1) / 7);
	}

	const aggregateDataByDate = useCallback(
		(alerts) => {
			const aggregatedData = {};

			alerts
				.filter((alert) => alert.status === "APPROVED")
				.forEach((alert) => {
					const dateKey = getFormattedDate(alert.timestamp);
					if (!aggregatedData[dateKey]) {
						aggregatedData[dateKey] = {
							timestamp: dateKey,
							count: 0,
						};
					}
					aggregatedData[dateKey].count += 1;
				});

			return Object.values(aggregatedData);
		},
		[getFormattedDate],
	);

	useEffect(() => {
		if (alerts && alerts.length > 0) {
			setTransformedAlerts(aggregateDataByDate(alerts));
		}
	}, [alerts, aggregateDataByDate]);

	return (
		<ResponsiveContainer>
			<AreaChart
				data={transformedAlerts}
				margin={{ top: 5, left: 10, right: 25, bottom: 5 }}
			>
				<XAxis dataKey="timestamp" padding={{ right: 25 }} />
				<YAxis />
				<Tooltip />
				<CartesianGrid stroke="#f5f5f5" />
				<Area
					type="monotone"
					dataKey="count"
					stroke="#ff7300"
					fill="#FFA07A"
					dot={{ stroke: "red", strokeWidth: 5 }}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default HistoryPageAreaGraph;
