import { useState } from "react";

import {
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    LinearProgress,
    CircularProgress,
} from "@mui/material";

import ProgressArc from "./ProgressArc";
import useWindowSize from "../../../utils/useWindowSize";
import HistoryPageAreaGraph from "./HistoryPageAreaGraph";
import HistoryPageLineChart from "./HistoryPageLineChart";

import "./historyPageGraph.styles.css";

export const FILTER_VALUES = {
    YEARLY: "Yearly",
    MONTHLY: "Monthly",
    WEEKLY: "Weekly",
};

const HistoryPageGraphContainer = ({
    alerts,
    approvalRate,
    activeCompanyCameras,
    hasMoreThanOneYearOfAlerts,
}) => {
    const { width } = useWindowSize();
    const [filterValue, setFilterValue] = useState(FILTER_VALUES.MONTHLY);

    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
    };

    const CircularProgressWithLabel = (props) => {
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                    variant="determinate"
                    size={width < 1200 ? 100 : 150}
                    thickness={4}
                    {...props}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <p className="history-page-graph-title-txt">{`${Math.round(
                        props.value
                    )}%`}</p>
                </Box>
            </Box>
        );
    };

    return (
        <div className="history-page-graph-document">
            <div className="history-page-graph-line-chart-row-wrapper">
                <div className="history-page-graph-line-chart-container">
                    <div className="history-page-graph-line-chart-title-row">
                        <p className="history-page-graph-title-txt">
                            Fire alert activity analysis
                        </p>
                    </div>
                    <div className="history-page-graph-line-chart-filter-row">
                        <div className="history-page-graph-line-chart-filter-container">
                            <FormControl fullWidth>
                                <InputLabel id="timespanFilterLabel">
                                    Timespan
                                </InputLabel>
                                <Select
                                    labelId="timespanFilterLabel"
                                    id="timespanFilter"
                                    label="timespan"
                                    value={filterValue}
                                    onChange={handleFilterChange}
                                    disabled={alerts.length === 0}
                                >
                                    {hasMoreThanOneYearOfAlerts && (
                                        <MenuItem value={FILTER_VALUES.YEARLY}>
                                            {FILTER_VALUES.YEARLY}
                                        </MenuItem>
                                    )}
                                    <MenuItem value={FILTER_VALUES.WEEKLY}>
                                        {FILTER_VALUES.WEEKLY}
                                    </MenuItem>
                                    <MenuItem value={FILTER_VALUES.MONTHLY}>
                                        {FILTER_VALUES.MONTHLY}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="history-page-graph-line-chart">
                        <HistoryPageAreaGraph
                            alerts={alerts}
                            period={filterValue}
                        />
                    </div>
                </div>
                <div className="history-page-graph-line-chart-row-right-side-wrapper">
                    <div className="history-page-graph-line-chart-row-right-side-approval-wrapper">
                        <div className="history-page-graph-line-chart-title-row">
                            <p className="history-page-graph-title-txt">
                                Threats approval rate
                            </p>
                        </div>
                        <div className="history-page-graph-line-chart-row-progress-arc-wrapper">
                            <ProgressArc percentage={91} />
                        </div>
                    </div>
                    <div className="history-page-graph-line-chart-row-right-side-approval-wrapper">
                        <div className="history-page-graph-line-chart-title-row">
                            <p className="history-page-graph-title-txt">
                                Camera health
                            </p>
                        </div>
                        <div className="history-page-graph-line-chart-row-progress-arc-wrapper">
                            {activeCompanyCameras.map((camera) => (
                                <Box sx={{ width: "100%" }}>
                                    <p className="history-page-graph-subtitle-txt">
                                        {camera.name}
                                    </p>
                                    <LinearProgress
                                        variant="determinate"
                                        color="graphOrange"
                                        value={91}
                                        sx={{
                                            height: "15px",
                                            borderRadius: "6px",
                                        }}
                                    />
                                </Box>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="history-page-graph-second-chart-row-wrapper">
                <div className="history-page-graph-second-chart-wrapper">
                    <div className="history-page-graph-line-chart-title-row">
                        <p className="history-page-graph-title-txt">Triggers</p>
                    </div>
                    <HistoryPageLineChart alerts={alerts} />
                </div>
                <div className="history-page-graph-second-chart-coverage-wrapper">
                    <div className="history-page-graph-line-chart-title-row">
                        <p className="history-page-graph-title-txt">
                            Area coverage
                        </p>
                    </div>
                    <div className="history-page-graph-line-chart-title-coverage-spinner-wrapper">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "25px",
                            }}
                        >
                            <p className="history-page-graph-title-txt">
                                Ibricevina
                            </p>
                            <CircularProgressWithLabel
                                value={41}
                                color="graphOrange"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "25px",
                            }}
                        >
                            <p className="history-page-graph-title-txt">
                                Zagoric
                            </p>
                            <CircularProgressWithLabel
                                value={37}
                                color="graphOrange"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "25px",
                            }}
                        >
                            <p className="history-page-graph-title-txt">
                                Malo brdo
                            </p>
                            <CircularProgressWithLabel
                                value={47}
                                color="graphOrange"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HistoryPageGraphContainer;
