import React from "react";
import { connect } from "react-redux";

import Activity from "./components/Activity/Activity";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";

import "./styles.profilePage.css";

const ProfilePage = ({ user }) => {
	if (!user) return null;
	return (
		<div className="profile-page-body">
			<div className="personal-info-wrapper">
				<PersonalInfo user={user} />
			</div>
			<div className="activity-info-wrapper">
				<Activity user={user} />
			</div>
			{/* <div className='companies-wrapper'>
        <Companies user={user} />
      </div> */}
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
});

export default connect(mapStateToProps)(ProfilePage);
